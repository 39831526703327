.modal-backdrop {
    opacity: .5;
}

.modal-header,
.alert {
    .close {
        padding: 0.5rem 1.75rem;
        background: transparent;
        border: 0;
        font-size: 25px;
        position: absolute;
        top: 0;
        right: 0;
        opacity: .5;
        width: 1em;
        height: 1em;
        z-index: 2;

        button {
            display: none;
        }
    }
}

.form-row {
    display: flex;
    flex-wrap: wrap;
}

.vhd__datepicker__input {
    height: $input-height !important;
    line-height: $input-height !important;
}

.form-check {
    .custom-control-input {
        border-radius: .25em;
        float: left;
        margin: 3px 3px 3px -19px !important;
    }
}

.check-group {
    input {
        position: absolute;
        clip: rect(0, 0, 0, 0);
        pointer-events: none;
    }
}


.dataTables_length {
    select {
        margin-left: 5px;
        margin-right: 5px;
    }
}

.datepicker__input {
    background-color: $input-bg;
    height: $input-height !important;
    line-height: 2.5 !important;
}

.vnis__input, .datepicker__wrapper, .datepicker__inner, .datepicker__input {
    background-color: $input-bg !important;
    color: $input-color !important;
    border-color: $input-border-color !important;
}

.datepicker__dummy-wrapper {
    overflow: hidden;
    border-color: $input-border-color !important;
}

.datepicker__input:focus {
    outline: 0px solid !important;
}

// .datepicker__week-row {
//     border-bottom: 5px solid $hr-border-color !important;
// }
// .datepicker__months:before {
//     background: $hr-border-color !important;
// }
.datepicker__month-day--allowed-checkout:hover, .datepicker__month-day--valid:hover {
    background-color: $primary !important;
    color: $white !important;
}

.mx-datepicker {
    .mx-icon-calendar {
        color: $input-color !important;
    }
}

.blog-card {
    > h5 {
        position: absolute;
        margin-top: 10px !important;
    }

    .tabs {
        width: 100%;

        .card-body {
            padding: 1.25rem 0 0;
        }
    }
}


.modal-backdrop {
    opacity: .5;
}

.modal-header,
.alert {
    .close {
        padding: 0.5rem 1.75rem;
        background: transparent;
        border: 0;
        font-size: 25px;
        position: absolute;
        top: -3px;
        right: 0;
        opacity: .5;
        width: 1em;
        height: 1em;
        z-index: 2;

        button {
            display: none;
        }
    }
}

.form-row {
    display: flex;
    flex-wrap: wrap;
}

.vhd__datepicker__input {
    height: $input-height !important;
    line-height: $input-height !important;
}

.form-check {
    .custom-control-input {
        border-radius: .25em;
        float: left;
        margin: 3px 3px 3px -19px !important;
    }
}

.check-group {
    input {
        position: absolute;
        clip: rect(0, 0, 0, 0);
        pointer-events: none;
    }
}

.wh-64 {
    width: 64px !important;
    height: 64px !important;
}

.ml-5 {
    margin-left: 5px !important;
}

.mr-5 {
    margin-right: 5px !important;
}

.mr-15 {
    margin-right: 15px !important;
}

.mx-datepicker {
    width: 149px !important;
}

.body {
    background: #efefef !important;
}

.font-weight-bold {
    font-weight: bold !important;
}

.background__login {
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: bottom;
}


[tooltip] {
    position: relative;
}

[tooltip]::before,
[tooltip]::after {
    line-height: 1;
    user-select: none;
    pointer-events: none;
    position: absolute;
    display: none;
    opacity: 0;

    /* opinions */
    text-transform: none;
    font-size: .9em;
}

[tooltip]::before {
    content: '';
    z-index: 1001;
    border: 5px solid transparent;
}

[tooltip]::after {
    content: attr(tooltip); /* magic! */
    z-index: 1000;

    /* most of the rest of this is opinion */
    font-family: Helvetica, sans-serif;
    text-align: center;

    /*
    Let the content set the size of the tooltips
    but this will also keep them from being obnoxious
    */
    min-width: 3em;
    max-width: 21em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    /* visible design of the tooltip bubbles */
    padding: 1ch 1.5ch;
    border-radius: .3ch;
    box-shadow: 0 1em 2em -.5em rgba(0, 0, 0, 0.35);
    background: #333;
    color: #fff;
}

[tooltip]:hover::before,
[tooltip]:hover::after {
    display: block;
}

/* don't show empty tooltips */
[tooltip='']::before,
[tooltip='']::after {
    display: none !important;
}

[tooltip][flow^="down"]::before {
    top: 100%;
    border-top-width: 0;
    border-bottom-color: #333;
}

[tooltip][flow^="down"]::after {
    top: calc(100% + 5px);
}

[tooltip][flow^="down"]::before,
[tooltip][flow^="down"]::after {
    left: 50%;
    transform: translate(-50%, .5em);
}

@keyframes tooltips-vert {
    to {
        opacity: .9;
        transform: translate(-50%, 0);
    }
}

@keyframes tooltips-horz {
    to {
        opacity: .9;
        transform: translate(0, -50%);
    }
}

[tooltip]:not([flow]):hover::before,
[tooltip]:not([flow]):hover::after,
[tooltip][flow^="up"]:hover::before,
[tooltip][flow^="up"]:hover::after,
[tooltip][flow^="down"]:hover::before,
[tooltip][flow^="down"]:hover::after {
    animation: tooltips-vert 300ms ease-out forwards;
}


.multiselect__content-wrapper {
    z-index: 9999;
}

.forest__entry-table {
    tr {
        td:first-child {
            width: 20%;
        }
    }

    .forestSubTitle {
        text-align: right;
    }
}

.pd-i {
    padding: inherit !important;
}

.table-fixed {
    table-layout: fixed !important;
}

.white-pre-wrap {
    white-space: pre-wrap !important;
}

#monthlyProgress {
    tr {
        th:first-child {
            width: 5%;
        }

        th:nth-child(2) {
            width: 8%;
        }

        th:nth-child(4) {
            width: 8%;
        }
    }
}

.task-box {
    cursor: move;
}


.border-right-2 {
    border-right: 2px solid red;
}

.border-left-2 {
    border-left: 2px solid red;
}

.mr-2 {
    margin-right: 4px;
}


.table-head, .table-foot {
    background: #2460b9;
    color: #fff;
}

.pd-i {
    padding: inherit !important;
}

.table-fixed {
    table-layout: fixed !important;
}

.white-pre-wrap {
    white-space: pre-wrap !important;
}

#monthlyProgress {
    tr {
        th:first-child {
            width: 5%;
        }

        th:nth-child(2) {
            width: 8%;
        }

        th:nth-child(4) {
            width: 8%;
        }
    }
}

.task-box {
    cursor: move;
}

.th-500 {
    height: 500px;
}

#monthlyProgramReport {
    .table {
        thead {
            position: sticky;
            position: -webkit-sticky;
            top: 0;
            z-index: 1;
            background-color: #2460b9;
            color: #fff;
            padding: 6px !important;
        }

        td {
            padding: 6px !important;
        }

        tr:last-child {
            position: sticky;
            position: -webkit-sticky;
            bottom: 0;
            z-index: 1;
            background-color: #2460b9;
            color: #fff;
            padding: 6px !important;
        }
    }
}

.ml-2rem {
    margin-left: 2rem;
}

.text-right {
    text-align: end;
}

.forestSubTitle {
    text-align: end;
}

#forestReport {
    tr:first-child {
        width: 15%;
    }
}

.wh-100 {
    width: 100px !important;
}


.tippani {
    display: flex;
    justify-content: center;
    align-content: center;

    &__container {
        position: relative;
        background-color: rgb(255, 255, 255);
        overflow: hidden;
        width: 8.27in;
        min-height: 11.69in;
        padding: 1.5rem 3rem;
        display: flex;
        flex-direction: column;
    }

    &__header {
        display: flex;
        flex-direction: column;
    }

    &__main-header {
        display: flex;
    }

    &__logo {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .file__title {
        font-size: 22px;
        font-weight: 600;
    }

    &__invoiceTime {
        font-size: 20px;
    }

    #comment__header {
        width: 100%;

        p {
            color: red;
            font-size: 28px;
            font-weight: 700;
            margin-bottom: 0;

            &:first-child {
                font-size: 20px;
                font-weight: 600;
            }

            &:last-child {
                font-size: 20px;
                font-weight: 600;
            }
        }
    }

    .comment__content {
        &:before {
            content: "";
            border-left: 2px solid red;
            position: absolute;
            left: 90px;
            right: 0;
            top: 228px;
            bottom: 0;
        }
    }
}

.post {
    position: relative;
    padding-top: 60px;

    &__preview {
        background: rgb(122, 133, 153);
        width: calc(100% - 840px);
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;

        &-container {
            height: 100vh;
            display: flex;
            align-items: center;
            justify-content: center;
            position: sticky;
            top: 0;
            margin-top: 45px;

            .preview__notice {
                display: flex;
                -webkit-box-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                align-items: center;
                position: absolute;
                inset: 40px 74px 74px;

                &-container {
                    display: inline-block;
                    position: relative;
                }

                .notice {
                    position: relative;
                    background-color: rgb(255, 255, 255);
                    overflow: hidden;
                    width: 410px;
                    height: 515px;
                    padding: 0 10px;
                    display: flex;
                    flex-direction: column;

                    div {
                        font-size: 10px;
                    }

                    &__date {
                        display: flex;
                        justify-content: flex-end;
                        margin: 10px 0;
                    }
                }
            }
        }
    }

    &__form {
        min-height: calc(100vh - 80px);
        width: 840px;
        padding: 48px;
    }
}

#normal__header {
    width: 100%;

    p {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 0;

        &:first-child {
            font-size: 18px;
            font-weight: 600;
        }

        &:last-child {
            font-size: 18px;
            font-weight: 600;
        }
    }
}

.text-red {
    color: red;
}

.red-border {
    margin-top: 5px;
    border-bottom: 2px solid red;
}


.comment {
    &__content {
        margin-left: 50px;
    }

    &__description {
        padding: 10px 0 0 10px;
        line-height: 1.5rem;
        font-weight: 400;
        text-align: justify;
    }
}


.sticky-br {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 100;
    border-radius: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: scale(0.92);

    &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        color: #fff;
        font-size: 28px;
        font-weight: 600;
    }
}

.pd-1 {
    padding: 1rem;
}

[contenteditable] {
    outline: 0px solid transparent;
}

.ml-3 {
    margin-left: 15px;
}

.justify-text {
    text-align: justify;
}

.ql-align-center {
    text-align: center;
}

.letter-head {
    p {
        margin-bottom: 0px !important;
    }

    p:nth-child(4) {
        font-size: 30px;
        font-weight: 400;
    }
}

.letter-head p:nth-child(4) {
    font-size: 30px;
    font-weight: 400;
}

.table-date {
    display: flex;
    width: 100px !important;
}

.column-sticky {
    position: sticky;
    right: 0;
}

.white-space-break {
    white-space: break-spaces;
}

.header-sticky {
    position: sticky;
    left: 0;
    background-color: #2460b9 !important;
    z-index: 2;
}

.cfug-sticky {
    position: sticky;
    left: 0;
    background-color: #fff !important;
}

.sticky-second {
    left: 100px !important;
}

.sticky-second-80 {
    left: 80px !important;
}

.page {
    width: 216mm;
    min-height: 279mm;
    padding: 5mm 10mm 10mm 10mm;
    background: white;
}

.transfer_assistant th {
    text-align: center;
    padding: 0.75rem 0.4rem !important;
}

p {
    input[type=text] {
        border-bottom: 1px solid #000000;
    }
}

.page {
    tr {
        word-break: break-word;
    }
}


