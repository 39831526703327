//
// print.scss
//

// Used invoice page
@media print {
    .vertical-menu,
    .right-bar,
    .page-title-box,
    .navbar-header,
    .footer {
        display: none !important;
    }
    .card-body,
    .main-content,
    .right-bar,
    .page-content,
    body {
        padding: 0;
        margin: 0;
    }

    .card {
        border: none;
        background: none;
        box-shadow: none;
    }

    #industryRegistration, #industryAdministration {
        .table-responsive {
            display: block;
            width: 100%;
            overflow-x: hidden;
            -webkit-overflow-scrolling: touch;
            margin-left: 20px;
        }

        .table th, td {
            line-height: 1;
        }

        thead th,
        tbody th {
            font-weight: 600;
            vertical-align: middle;
            text-align: center;
        }

        thead th {
            border-bottom-width: 0;
            padding: 8px !important;
        }

        .table {
            display: table;
            border-collapse: collapse;
            box-sizing: border-box;
            text-indent: initial;
            border-spacing: 2px;
            border-color: grey;
        }

        .table-bordered th,
        .table-bordered td {
            border: 1px solid rgba(72, 94, 144, 0.16);
        }

        .row::after {
            content: "";
            clear: both;
            display: table;
        }
    }

    #industryRegistration {
        .table {
            transform: scale(0.60);
            transform-origin: 0 0;
        }

        thead th {
            border-bottom-width: 0;
            padding: 5px !important;
        }
    }

    #industryAdministration {
        .table {
            transform: scale(0.92);
            transform-origin: 0 0;
        }
    }

    #commercialRegistration {
        .table {
            transform: scale(0.60);
            transform-origin: 0 0;
        }
    }
}
